import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { debounce, uniqueId } from 'lodash';
import cache, { getScriptStub } from '../utils/cache';
import getPlatform from '../utils/get-platform';
import getScriptMap from '../utils/get-script-map';
import getLink from '../utils/get-link';

type Props = {
  currentZoom: Number,
  secure: Boolean,
  setHMapState: Function,
  resizeMap: Function,
  appId: String,
  appCode: String,
  center: Object,
  hidpi: Boolean,
  interactive: Boolean,
  zoom: Number,
  map: Object,
  children: Object
};

class HMapInstance extends Component<Props> {
  componentWillMount() {
    const { secure } = this.props;
    this.render = this.render.bind(this);

    cache(getScriptMap(secure));

    getLink(
      `${secure ? 'https:' : null}//js.api.here.com/v3/3.1/mapsjs-ui.css`,
      'HERE Maps UI',
    );
  }

  componentDidMount() {
    const setHMapState = this.props.setHMapState;
    this.debouncedResizeMap = debounce(this.props.resizeMap, 200);

    getScriptStub('mapEventsScript').onLoad(() => {
    
      const { apiKey, center, hidpi, interactive, secure, zoom } =
        this.props;

      // get the platform to base the maps on
      const platform = getPlatform({
        apikey: apiKey,
        useHTTPS: secure,
      });

      const rasterTileService = platform.getRasterTileService({
          'format': 'png8',
          'resource': 'base',
          'queryParams': { 'style': 'explore.satellite.day', ppi: 400 }
      });
      const rasterTileProvider = new H.service.rasterTile.Provider(rasterTileService);
      const rtsLayer = new H.map.layer.TileLayer(rasterTileProvider);

      const defaultLayers = platform.createDefaultLayers();

      defaultLayers.vector.normal.map.setMin(9);
      defaultLayers.vector.normal.map.setMax(20);

      /* eslint-disable */
      const hereMapEl = ReactDOM.findDOMNode(this);
      const map = new H.Map(
        hereMapEl.querySelector('.map-container'),
        defaultLayers.vector.normal.map,
        {
          zoom,
          center,
          pixelRatio: hidpi ? 2 : 1,
        },
      );
      if (interactive !== false) {
      
        // make the map interactive
        // MapEvents enables the event system
        // Behavior implements default interactions for pan/zoom
        const behavior = new H.mapevents.Behavior(
          new H.mapevents.MapEvents(map),
        );
        // behavior.disable(H.mapevents.Behavior.WHEELZOOM);

        const mapSettingsControl = new H.ui.MapSettingsControl({
          'baseLayers': [
            {
              'label': 'Map View',
              'layer': defaultLayers.vector.normal.map
            },
            {
              'label': 'Satellite',
              'layer': rtsLayer
            }
          ],
          'layers': [
            // POPULATE WITH TRAFFIC LAYERS
          ],
          'alignment': H.ui.LayoutAlignment.BOTTOM_RIGHT
        });      

        // create the default UI for the map
        const scalebarControl = new H.ui.ScaleBar({
          'alignment': H.ui.LayoutAlignment.BOTTOM_RIGHT
        });
        const zoomControl = new H.ui.ZoomControl({
          'alignment': H.ui.LayoutAlignment.RIGHT_MIDDLE
        });

        const ui = new H.ui.UI(map);
        ui.addControl('zoom', zoomControl);
        ui.addControl('mapsettings', mapSettingsControl);
        ui.addControl('scalebar', scalebarControl);
        
        setHMapState('behavior', behavior);
        setHMapState('ui', ui);
      }

      // save the geocoder platform
      const geocoder = platform.getGeocodingService();

      // make the map resize when the window gets resized
      window.addEventListener('resize', this.debouncedResizeMap);

      // Listener for zoom events
      this.setState({loadCount: 0});
      map.addEventListener('mapviewchangeend', () => this.zoomListener());

      // Listener for cursors
      map.addEventListener(
        'pointermove',
        function (event) {
          if (
            event.target instanceof H.map.Marker ||
            event.target instanceof H.map.DomMarker
          ) {
            map.getViewPort().element.style.cursor = 'pointer';
          } else {
            map.getViewPort().element.style.cursor = 'auto';
          }
        },
        false,
      );

      // attach the map object to the component"s state
      setHMapState('map', map);
      setHMapState('geocoder', geocoder);
    });
  }

  componentWillUnmount() {
    // make the map resize when the window gets resized
    window.removeEventListener('resize', this.debouncedResizeMap);
  }

  zoomListener() {
    const { map, setHMapState } = this.props;
    this.setState((prevState) => {return {loadCount: prevState.loadCount + 1} });
    setHMapState('loadCount', this.state.loadCount);
    setHMapState('currentZoom', map.getZoom());
  }

  render() {
    return (
      <div style={{ height: '100%', width: '100%' }}>
        <div
          className="map-container"
          id={`map-container-${uniqueId()}`}
          style={{ height: 'calc(100vh - 74px)', width: '100vw' }}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default HMapInstance;
